import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createBrowserHistory } from 'history';

import ReactGA from 'react-ga';
import Loading from '../loading/loading';
import TelaCPF from '../telaCPF/telaCPF';
import Error from '../telaErro/telaErro';
import ErrorModal from '../../components/errorModal/errorModal';
import TelaInicial from '../telaInicial/telaInicial';
import BoletoGerado from '../boletoGerado/boletoGerado';
import TelaPDF from '../telaPDF/telaPDF';
import TelaDesconhece from '../telaDesconhece/telaDesconhece';

import CPFService from '../../services/cpf';

export default function Main() {
	ReactGA.initialize('UA-96535900-13');
	ReactGA.pageview(window.location.pathname);

	const estado = useSelector(state => state.estadoReducer);
	const path = createBrowserHistory().location.pathname;
	const dispatch = useDispatch();
	const cpfService = new CPFService(estado);

	useEffect(() => {
		const url = path.split('/').filter(n => n);
		dispatch({ type: 'SET_URL', payload: url });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [path]);

	useEffect(() => {
		if (estado.id) {
			setTimeout(() => window.location.reload(), 1000 * 60 * 5);
			carregaPagina();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [estado.id]);

	const carregaPagina = () => {
		cpfService
			.getCPF()
			.then(data => {
				if (data.success) {
					// salva cookie pra inserir no historico só 1 vez
					const historico = JSON.parse(localStorage.getItem('historico'));

					if (historico && historico.length > 0) {
						localStorage.setItem('historico', JSON.stringify([...historico, estado.id_tel]));
					} else {
						localStorage.setItem('historico', JSON.stringify([estado.id_tel]));
					}
					dispatch({ type: 'SET_USUARIO', payload: data.result });
					dispatch({ type: 'SET_CONFIRMA_CPF' });
					// 	mensagemacao: data.result.mensagemacao,
					// 	mensagemacaogerado: data.result.mensagemacaogerado,
				} else {
					dispatch({
						type: 'SET_ERROR',
						payload: { errorMessage: data.mensagem, errorTitle: 'Não conseguimos gerar seu boleto :(' }
					});
				}
			})
			.catch(err => {
				dispatch({
					type: 'SET_ERROR',
					payload: { errorMessage: 'Erro no Servidor', errorTitle: 'Não conseguimos gerar seu boleto :(' }
				});
			});
	};

	return (
		<>
			{estado.loadingPage ? <Loading /> : null}
			{estado.confirmaCpf ? <TelaCPF /> : null}
			{estado.telaInicial ? <TelaInicial /> : null}
			{estado.boletoGerado ? <BoletoGerado /> : null}
			{estado.visualizarPDF ? <TelaPDF /> : null}
			{estado.error ? <Error /> : null}
			{estado.errorModal ? <ErrorModal /> : null}
			{estado.telaDesconhece ? <TelaDesconhece /> : null}
		</>
	);
}
