import React from 'react';
import Whatsapp from '../../imgs/botao-whatsapp-sky.png';

import './icone-whatsapp.css';

const iconeWhatsapp = props =>{
    return (
        
            <a href="https://wa.me/551139577707?text=Olá" target="_blank" rel="noopener noreferrer">
                <img className='whatsapp' alt='Whatsapp' src={Whatsapp} />
            </a>
        
    );
};

export default iconeWhatsapp;